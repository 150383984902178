import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";


export const Message = () => {
  const search = useMemo(
    () => [
      { type: "select", label: "ID", key: "id" },
      { type: "input", label: "用户", key: "user" },
    ],
    []
  );
  return (
    <Page
      search={search}
      rowKey="id"
      resultColumns={COLUMN[TYPE.MSG]}
      url="/browser/MESSAGE_LIST"
      dataResolver={data => data}
    />
  );
};
