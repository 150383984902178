import { Layout, Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import "./Layout.scss";
import {
  AndroidOutlined,
  AppstoreOutlined,
  ContactsOutlined,
  FileImageOutlined,
  HistoryOutlined,
  LinkOutlined,
  MessageOutlined,
  NumberOutlined,
  RocketOutlined,
  TabletOutlined,
  UserOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";

function getItem(label: React.ReactNode, key: string, icon?: React.ReactNode) {
  return {
    key,
    icon,
    label,
  };
}

const menus = [
  getItem("用户信息", "user", <UserOutlined />),
  getItem("设备信息", "device", <AndroidOutlined />),
  getItem("通讯录", "contact", <ContactsOutlined />),
  getItem("通话记录", "dial", <NumberOutlined />),
  getItem("短信记录", "message", <MessageOutlined />),
  getItem("浏览记录", "history", <HistoryOutlined />),
  getItem("应用记录", "software", <AppstoreOutlined />),
  getItem("图片信息", "image", <FileImageOutlined />),
  getItem("WIFI信息", "network", <WifiOutlined />),
  // getItem("密码信息", "password", <LockOutlined />),
  getItem("定位信息", "location", <TabletOutlined />),
  getItem("代理设置", "proxy", <RocketOutlined />),
  getItem("导航设置", "nav", <LinkOutlined />),
];

export const AppLayout = () => {
  const navigate = useNavigate();
  const session = useSessionContext();
  const [activeKey, setActiveKey] = useState([menus[0].key]);

  useEffect(() => {
    const location = window.location.pathname.split("/")[1];
    const matchedMenu = menus.find((menu) => menu.key === location);

    if (matchedMenu) {
      setActiveKey([matchedMenu.key]);
    } else {
      setActiveKey([]);
    }
  }, []);

  console.log(session);

  if (session.loading) {
    return null;
  }

  return (
    <SessionAuth>
      <Layout className="app-layout">
        <Layout.Header style={{ height: 64, padding: "0 20px" }}>
          <div className="title">阵地系统管理后台</div>
        </Layout.Header>
        <Layout.Content style={{ height: "calc(100vh - 64px)" }}>
          <Layout style={{ height: "100%" }}>
            <Layout.Sider style={{ height: "100%", overflowX: "auto" }}>
              <Menu
                selectedKeys={activeKey}
                theme="dark"
                items={menus}
                onSelect={(menu) => {
                  setActiveKey(menu.selectedKeys);
                  navigate({
                    pathname: menu.selectedKeys[0],
                  });
                }}
              />
            </Layout.Sider>
            <Layout.Content
              style={{
                padding: "20px 16px",
                height: "100%",
                overflowX: "auto",
              }}
            >
              <Outlet />
            </Layout.Content>
          </Layout>
        </Layout.Content>
      </Layout>
    </SessionAuth>
  );
};
