import "./App.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { PreBuiltUIList, superTokensConfig } from "./config";
import { Device } from "./pages/Device";
import { AppLayout } from "./components/Layout/Layout";
import { NotMatched } from "./pages/NotMatched";
import { Contact } from "./pages/Contact";
import { User } from "./pages/User";
import { Location } from "./pages/Location";
import { Dial } from "./pages/Dial";
// import { Bookmark } from "./pages/Bookmark";
import { History } from "./pages/History";
import { Message } from "./pages/Message";
import { Software } from "./pages/Software";
import { Proxy } from "./pages/Proxy";
import { Network } from "./pages/Network";
import { DeviceDetail } from "./pages/DeviceDetail";
// import { Password } from "./pages/Password";
import { UserDetail } from "./pages/UserDetail";
import { Image } from "./pages/Image";
import { Nav } from "./pages/Navigation";

SuperTokens.init(superTokensConfig);

function App() {
  return (
    <SuperTokensWrapper key="router">
      <div className="App app-container">
        <Router>
          <div className="fill">
            <Routes>
              {/* This shows the login UI on "/auth" route */}
              {getSuperTokensRoutesForReactRouterDom(
                require("react-router-dom"),
                PreBuiltUIList
              )}

              {/* <Route
                key="root-simple"
                path="/"
                element={
                  <SessionAuth>
                    <Home />
                  </SessionAuth>
                }
              /> */}
              <Route
                key="root"
                path="/"
                element={
                  // <SessionAuth requireAuth>
                    <AppLayout />
                  // </SessionAuth>
                }
              >
                <Route key="device" path="device" element={<Device />} />
                <Route
                  key="device-detail"
                  path="device/:device"
                  element={<DeviceDetail />}
                />
                <Route
                  key="user-detail"
                  path="user/:user"
                  element={<UserDetail />}
                />
                <Route key="contact" path="contact" element={<Contact />} />
                <Route key="user" path="user" element={<User />} />
                <Route key="location" path="location" element={<Location />} />
                <Route key="dial" path="dial" element={<Dial />} />
                {/* <Route key="bookmark" path="bookmark" element={<Bookmark />} /> */}
                <Route key="history" path="history" element={<History />} />
                <Route key="message" path="message" element={<Message />} />
                <Route key="software" path="software" element={<Software />} />
                <Route key="proxy" path="proxy" element={<Proxy />} />
                <Route key="network" path="network" element={<Network />} />
                {/* <Route key="password" path="password" element={<Password />} /> */}
                <Route key="image" path="image" element={<Image />} />
                <Route key="nav" path="nav" element={<Nav />} />
                {/* <Route
                  key="device-detail"
                  path="device/:deviceId"
                  element={<DeviceDetail />}
                /> */}
                <Route key="not-matched" path="*" element={<NotMatched />} />
              </Route>
            </Routes>
          </div>
        </Router>
      </div>
    </SuperTokensWrapper>
  );
}

export default App;
