import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";
import { Amap } from "../components/Amap/Amap";

export const Location = () => {
  const search = useMemo(
    () => [{ type: "input", label: "设备 ID", key: "id" }],
    []
  );

  return (
    <>
      <Page
        title="定位列表"
        search={search}
        rowKey="id"
        resultColumns={COLUMN[TYPE.LOC]}
        url="/browser/LOCATION"
        dataResolver={(data) => data}
        renderOperations={(_, row) => <Amap key={row.id} data={row} />}
      />
    </>
  );
};
