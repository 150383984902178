export const opt = (label: string, value: string) => {
  return {
    label,
    value,
  };
};

export const col = (label: string, value: string, width = 200) => {
  return {
    label,
    key: value,
    width: 200
  };
};