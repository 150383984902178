import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";

export const Image = () => {
  const search = useMemo(
    () => [{ type: "input", label: "设备ID", key: "uuid" }],
    []
  );
  return (
    <Page
      title="WIFI列表"
      search={search}
      rowKey="id"
      resultColumns={COLUMN[TYPE.IMG]}
      url="/browser/IMAGE_LIST"
      dataResolver={(data) => data}
    />
  );
};
