import { useMemo, useRef, useState } from "react";
import { Page } from "../components/Page/Page";
import { Button, Form, Input, Modal, Space, Switch, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { service } from "../api";
import { COLUMN, TYPE } from "../constant";

export const Nav = () => {
  const [open, setOpen] = useState(false);
  const tableRef = useRef<any>();
  const [form] = Form.useForm();
  const initialValue = useMemo(() => {
    return {
      status: true,
    };
  }, []);
  const openAddNavDialog = () => {
    form.resetFields();
    setOpen(true);
  };

  const handleAddNav = async () => {
    const data = form.getFieldsValue();
    const matched = ["image", "url", "title"]
      .map((key) => data[key])
      .filter((item) => item === "" || item === undefined || item === null);

    if (matched.length > 0) {
      message.warning("存在未填信息");

      return;
    }

    try {
      await service
        .request({
          method: "POST",
          url: "/add/nav",
          data,
        })
        .catch((e) => {
          console.log(e);
        });
      tableRef.current?.reloadTable?.();
      setOpen(false);
    } catch (e) {}
  };

  const setChecked = (value: boolean) => {
    form.setFieldValue("status", value ? "1" : "0");
  };

  const handleDeleteNav = (row: any) => {
    Modal.confirm({
      title: `删除导航网址`,
      content: `确定删除导航网址「${row.name}」吗？`,
      onOk: () => {
        service
          .request({
            method: "POST",
            url: "/delete/nav",
            data: {
              id: row.id,
            },
          })
          .then(() => {
            tableRef.current.reloadTable();
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });
  };

  return (
    <>
      <Page
        ref={tableRef}
        title="导航列表"
        rowKey="id"
        resultColumns={COLUMN[TYPE.NAV]}
        url="/browser/NAV_LIST"
        action={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={openAddNavDialog}
          >
            添加导航站点
          </Button>
        }
        dataResolver={(data) => data}
        renderOperations={(_, row) => (
          <Space>
            <Button type="primary" danger onClick={() => handleDeleteNav(row)}>
              删除
            </Button>
          </Space>
        )}
      />

      <Modal
        title="添加导航网址"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleAddNav}
      >
        <Form
          style={{ margin: "24px 0" }}
          form={form}
          initialValues={initialValue}
        >
          <Form.Item label="标题" name="title">
            <Input placeholder="请输入导航标题" />
          </Form.Item>
          <Form.Item label="链接" name="url">
            <Input placeholder="请输入导航网址" />
          </Form.Item>
          <Form.Item label="分类" name="category">
            <Input placeholder="请输入导航分类" />
          </Form.Item>
          <Form.Item label="图片" name="image">
            <Input placeholder="请输入图片网址" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
