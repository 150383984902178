import { useMemo } from 'react';
import { Button, Image, Table, TableColumnType } from "antd";
import { ResultColumn } from "../Page/Page";
import { Link } from 'react-router-dom';

interface BrowserTableProps {
    data: Array<Record<string, any>>;
    rowKey: string;
    resultColumns: Array<ResultColumn & Partial<TableColumnType<void>>>;
    renderOperations?: (...data: any) => JSX.Element;
  }

export const BrowserTable =  (props: BrowserTableProps) => {
    const data = props. data;
    
  const tableColumns = useMemo(() => {
    const columns = props.resultColumns.map((item): Record<string, any> => {
      let render = null;

      if (item.key === 'uuid') {
        render = (_: any, row: any) => (
          <Link to={{ pathname: `/device/${row.uuid}` }}>{row.uuid}</Link>
        )
      }

      if (item.key === 'browser_user') {
        render = (_: any, row: any) => {
          if (row.browser_user) {
            return <Link to={{ pathname: `/user/${row.browser_user.user_id}` }}>{row.browser_user.nickName}</Link>
          }

          return row.user_id || '';
        }
      }

      if (item.key === 'url_image') {
        render = (_: any, row: any) => {
          if (row.url_image?.url) {
            return <Image src={row.url_image.url} width={200} />
          }

          return row.url_image?.text || '';
        }
      }

      if (item.key === 'url_text') {
        render = (_: any, row: any) => {
          if (row.url_text?.url) {
            return <Button type="link" href={row.url_text.url} target="_blank">{row.url_text?.text}</Button>
          }

          return row.url_text?.text || '';
        }
      }

      if (item.key === 'base64_image') {
        render = (_: any, row: any) => {
          if (row.base64_image) {
            return <Image src={row.base64_image} width={200} />
          }

          return '';
        }
      }

      return {
        ...item,
        textWrap: "word-break",
        title: item.label,
        dataIndex: item.key,
        key: item.key,
        render
      };
    });

    if (props.renderOperations) {
      columns.push({
        title: "操作",
        dataIndex: "operations",
        key: "operations",
        align: "center",
        render: props.renderOperations,
      });
    }

    return columns;
  }, [props.resultColumns]);


    if (!data) {
        return null;
    }

    return <div style={{ overflowX: "auto" }}>
          <Table
            bordered
            dataSource={data}
            columns={tableColumns}
            pagination={false}
            rowKey={props.rowKey}
          ></Table>
        </div>
}