import { useMemo, useState, useEffect } from "react";
import { Page } from "../components/Page/Page";
import { Button, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { COLUMN, TYPE, TYPE_OPTIONS } from "../constant";
import { useParams } from "react-router-dom";
import { Amap } from "../components/Amap/Amap";
import { service } from "../api";
import { BrowserTable } from "../components/Table/Table";
import "../components/Page/Page.scss";

const taskColumns = [
  {
    label: "任务ID",
    key: "id",
  },
  {
    label: "任务名称",
    key: "loginName",
  },
  {
    label: "结束状态",
    key: "statusText",
  },
  {
    label: "创建时间",
    key: "create_at",
  },
  {
    label: "更新时间",
    key: "create_at",
  },
];

export const UserDetail = () => {
  const [type, setType] = useState(TYPE.CON);
  const [device, setDevice] = useState("-1");
  const [data, setData] = useState<any>([]);
  const options = useMemo(
    () =>
      [{ label: "全部", value: "-1" }].concat(
        data.map((item: any) => {
          return {
            label: item.uuid,
            value: item.uuid,
          };
        })
      ),
    [data]
  );
  const params = useParams();

  const extraQuery: Record<string, string> = useMemo(() => {
    const query: Record<string, string> = {};

    if (params.user) {
      query.user = params.user;
    }

    if (device !== "-1") {
      query.device = device;
    }

    return query;
  }, [device, params.user]);

  const columns = COLUMN[type].filter((item) => {
      return item.key !== "browser_user";
  });

  const deviceColumns = useMemo(() => {
    return COLUMN[TYPE.HRDW].filter((item) => item.key !== "browser_user");
  }, []);

  const renderOperations =
    type === TYPE.LOC ? (_: any, row: any) => <Amap data={row} /> : undefined;

  useEffect(() => {
    service
      .request({
        url: "/deviceOfUser",
        method: "GET",
        params: {
          user: params.user,
        },
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button icon={<ArrowLeftOutlined />}>返回列表页</Button>
      </div>

      <div style={{ marginBottom: "20px" }} className="result-table">
        <div className="table-title">
          <div className="table-title-text">用户关联的设备列表</div>
        </div>
        <BrowserTable
          rowKey="id"
          resultColumns={deviceColumns}
          data={data}
        ></BrowserTable>
      </div>

      <Page
        key="other-info"
        title="查询结果"
        action={
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 24 }}>
              <span style={{ fontWeight: "bold" }}>设备ID：</span>
              <Select
                style={{ minWidth: 150 }}
                value={device}
                onChange={setDevice}
                options={options}
              ></Select>
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>数据类别：</span>
              <Select
                style={{ minWidth: 150 }}
                value={type}
                onChange={setType}
                options={TYPE_OPTIONS}
              ></Select>
            </div>
          </div>
        }
        rowKey="id"
        extraQuery={extraQuery}
        resultColumns={columns}
        url={`/browser/${type}`}
        dataResolver={(data) => data}
        renderOperations={renderOperations}
      />
    </>
  );
};
