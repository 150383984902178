import { useRef, useState } from "react";
import { Button, Modal, message } from "antd";

export const Amap = (props: { data: any }) => {
  const [open, setOpen] = useState(false);
  const map = useRef<any>();
  const mapNode = useRef<HTMLDivElement>(null);

  const handleViewMap = () => {
    const row = props.data;
    const { longitude, latitude } = row;

    if (isNaN(Number(longitude)) || isNaN(Number(latitude))) {
      message.error("经纬度信息错误，无法使用地图查看信息");
      return;
    }

    setOpen(true);

    map.current = new (window as any).AMap.Map(mapNode.current, {
      resizeEnable: true,
      zoom: 12,
      center: [Number(longitude), Number(latitude)],
      mapStyle: "amap://styles/08539321a17cd7c322f76950f2c68b3f",
    });
    new (window as any).AMap.Marker({
      map: map.current,
      position: [Number(longitude), Number(latitude)],
    });
    new (window as any).AMap.plugin("AMap.ToolBar", function () {
      //异步加载插件
      var toolbar = new (window as any).AMap.ToolBar();
      map.current.addControl(toolbar);
    });
  };

  const handleDestroy = () => {
    setOpen(false);

    if (map.current) {
      map.current.destroy();
    }
  };

  return (
    <>
    <Button type="primary" onClick={handleViewMap}>查看地图</Button>
      <Modal
        width={800}
        title="查看地理位置"
        forceRender
        open={open}
        onCancel={handleDestroy}
        onOk={handleDestroy}
        footer={null}
      >
        <div
          style={{ marginTop: 20, width: "100%", height: 500 }}
          ref={mapNode}
        ></div>
      </Modal>
    </>
  );
};
