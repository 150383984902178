import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";

export const Device = () => {
  // const search = useMemo(
  //   () => [
  //     {
  //       type: "input",
  //       label: "设备ID",
  //       key: "id",
  //       placeholder: "设备ID，精确匹配",
  //     },
  //   ],
  //   []
  // );

  return (
    <Page
      title="设备列表"
      // search={search}
      rowKey="id"
      resultColumns={COLUMN[TYPE.HRDW]}
      url="/browser/HARDWARE"
      dataResolver={(data) => data}
    />
  );
};
