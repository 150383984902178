import { SuperTokensConfig } from "supertokens-auth-react/lib/build/types";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { API_URL, WEBSITE_URL } from "./env";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";

export const superTokensConfig: SuperTokensConfig = {
  languageTranslations: {
    translations: {
      zh: {
        BRANDING_POWERED_BY_START: "",
        BRANDING_POWERED_BY_END: " 版权所有",
        SOMETHING_WENT_WRONG_ERROR: "系统异常，请稍后重试",
        EMAIL_VERIFICATION_RESEND_SUCCESS: "验证码发送成功",
        EMAIL_VERIFICATION_SEND_TITLE: "验证你的邮箱/手机号",
        EMAIL_VERIFICATION_SEND_DESC_START: "",
        EMAIL_VERIFICATION_SEND_DESC_STRONG: "请点击此链接",
        EMAIL_VERIFICATION_SEND_DESC_END:
          " 我们向你发送验证码来证明这个邮箱/手机号归属于你.",
        EMAIL_VERIFICATION_RESEND_BTN: "重新发送邮件",
        EMAIL_VERIFICATION_LOGOUT: "退出登录 ",
        EMAIL_VERIFICATION_SUCCESS: "邮箱/手机号验证成功!",
        EMAIL_VERIFICATION_CONTINUE_BTN: "继续",
        EMAIL_VERIFICATION_CONTINUE_LINK: "继续",
        EMAIL_VERIFICATION_EXPIRED: "验证邮箱/手机号的链接已过期",
        EMAIL_VERIFICATION_ERROR_TITLE: "系统错误",
        EMAIL_VERIFICATION_ERROR_DESC: "系统出现了一个错误",
        EMAIL_VERIFICATION_LINK_CLICKED_HEADER: "验证邮箱/手机号",
        EMAIL_VERIFICATION_LINK_CLICKED_DESC:
          "请点击下面的按钮来验证邮箱地址/手机号",
        EMAIL_VERIFICATION_LINK_CLICKED_CONTINUE_BUTTON: "继续",
        EMAIL_PASSWORD_EMAIL_LABEL: "邮箱/手机号",
        EMAIL_PASSWORD_EMAIL_PLACEHOLDER: "请输入邮箱或手机号",

        EMAIL_PASSWORD_PASSWORD_LABEL: "密码",
        EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: "请输入密码",

        EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE: "登录账号",
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_START: "还未注册账号?",
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_SIGN_UP_LINK: "去注册",
        EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_END: "",
        EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK: "忘记密码？",
        EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: "登录",
        EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: "用户名或密码错误",

        EMAIL_PASSWORD_SIGN_UP_HEADER_TITLE: "注册账号",
        EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_START: "已经有账号了？",
        EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_SIGN_IN_LINK: "去登录",
        EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_END: "",
        EMAIL_PASSWORD_SIGN_UP_FOOTER_END: "",
        EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: "注册",

        EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS: "用户名已存在，请登录",

        EMAIL_PASSWORD_RESET_HEADER_TITLE: "重置密码",
        EMAIL_PASSWORD_RESET_HEADER_SUBTITLE: "我们将会给您发送一封邮件",
        EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: "你的用户名",
        EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: "重置密码的邮件将会被发送",
        EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: ", 如果邮箱是属于你的",
        EMAIL_PASSWORD_RESET_RESEND_LINK: "再次发送邮件或者修改邮箱地址",
        EMAIL_PASSWORD_RESET_SEND_BTN: "发送邮件",
        EMAIL_PASSWORD_RESET_SIGN_IN_LINK: "去登录",

        EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: "密码修改成功!",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC:
          "密码修改成功，请使用新密码登录",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: "登录",

        EMAIL_PASSWORD_NEW_PASSWORD_LABEL: "新密码",
        EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: "请输入新密码",
        EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: "确认新密码",
        EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "请再次输入新密码",

        EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: "修改密码",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: "输入新的密码",
        EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: "修改密码",
        EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR: "密码重置",

        ERROR_EMAIL_NON_STRING: "用户名必须为字符串",
        ERROR_EMAIL_INVALID: "用户名错误",

        ERROR_PASSWORD_NON_STRING: "密码必须为字符串",
        ERROR_PASSWORD_TOO_SHORT:
          "密码长度不够，请至少输入8个字符，必须包含字母和数字",
        ERROR_PASSWORD_TOO_LONG: "密码长度不可超过 100",
        ERROR_PASSWORD_NO_ALPHA: "密码必须包含字母",
        ERROR_PASSWORD_NO_NUM: "密码必须包含数字",
        ERROR_CONFIRM_PASSWORD_NO_MATCH: "密码不一致",

        ERROR_NON_OPTIONAL: "当前项必填",

        "This email already exists. Please sign in instead.":
          "用户名已存在，请登录",
        "Field is not optional": "当前项必填",
        "Password must contain at least 8 characters, including a number":
          "密码长度不够，请至少输入8个字符，必须包含字母和数字",
        "Password's length must be lesser than 100 characters":
          "密码长度不可超过 100",
        "Password must contain at least one alphabet": "密码必须包含字母",
        "Password must contain at least one number": "密码必须包含数字",
        "Email is invalid": "用户名错误",
      },
    },
    defaultLanguage: "zh",
  },
  appInfo: {
    appName: "阵地系统管理平台",
    apiDomain: API_URL,
    websiteDomain: WEBSITE_URL,
    apiBasePath: "/api/auth",
    websiteBasePath: "/auth",
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    EmailPassword.init({
      signInAndUpFeature: {
        signUpForm: {
          formFields: [
            {
              id: "email",
              label: "邮箱/手机号",
              placeholder:
                "请输入邮箱或手机号，仅支持qq和163邮箱以及内地手机号",
              validate: async (value: string) => {
                if (value.indexOf("@") >= 0) {
                  if (value.endsWith("@qq.com") || value.endsWith("@163.com")) {
                    return undefined;
                  }

                  return "邮箱需以 @qq.com 或者 @163.com 结尾";
                }

                if (value.startsWith("+")) {
                  return "请勿填写 +86 等漫游区号";
                }

                if (isNaN(Number(value))) {
                  return "邮箱格式错误";
                }

                if (value.length !== 11) {
                  return "手机号格式错误";
                }
              },
            },
            {
              id: "nickname",
              label: "昵称",
              placeholder: "请输入昵称",
              validate: async () => undefined,
            },
          ],
        },
        signInForm: {
          formFields: [
            {
              id: "email",
              label: "邮箱/手机号",
              placeholder: "请输入邮箱或手机号",
            },
          ],
        },
      },
    }),
    Session.init({
      tokenTransferMethod: "header",
    }),
    EmailVerification.init({
      mode: "OPTIONAL",
    }),
  ],
};

export const recipeDetails = {
  docsLink: "https://supertokens.com/docs/emailpassword/introduction",
};

export const PreBuiltUIList = [
  EmailPasswordPreBuiltUI,
  EmailVerificationPreBuiltUI,
];
