import { useMemo, useRef, useState } from "react";
import { Page } from "../components/Page/Page";
import { Button, Form, Input, Modal, Space, Switch, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { service } from "../api";
import { COLUMN, TYPE } from "../constant";

export const Proxy = () => {
  const [open, setOpen] = useState(false);
  const tableRef = useRef<any>();
  const [form] = Form.useForm();
  const initialValue = useMemo(() => {
    return {
      status: true,
    };
  }, []);
  const openAddProxyDialog = () => {
    form.resetFields();
    setOpen(true);
  };

  const handleAddProxy = async () => {
    const data = form.getFieldsValue();
    const matched = ["name", "description", "url", "status", "region"]
      .map((key) => data[key])
      .filter((item) => item === "" || item === undefined || item === null);

    if (matched.length > 0) {
      message.warning("存在未填信息");

      return;
    }

    try {
      await service.request({
        method: "POST",
        url: "/add/proxy",
        data,
      })
      .catch((e) => {
        console.log(e);
      });
      tableRef.current?.reloadTable?.();
      setOpen(false);
    } catch (e) {}
  };

  const setChecked = (value: boolean) => {
    form.setFieldValue("status", value ? "1" : "0");
  };

  const handleDeleteProxy = (row: any) => {
    Modal.confirm({
      title: `删除节点`,
      content: `确定删除节点「${row.name}」吗？`,
      onOk: () => {
        service
          .request({
            method: "POST",
            url: "/delete/proxy",
            data: {
              id: row.id,
            },
          })
          .then(() => {
            tableRef.current.reloadTable();
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });
  };

  const handleToggleProxy = (row: any) => {
    service
          .request({
            method: "POST",
            url: "/toggle/proxy",
            data: {
              id: row.id,
              enabled: row.status === "0",
            },
          })
          .then(() => {
            tableRef.current.reloadTable();
          })
          .catch((e) => {
            console.log(e);
          });
  };

  return (
    <>
      <Page
        ref={tableRef}
        title="代理列表"
        rowKey="id"
        resultColumns={COLUMN[TYPE.PROXY]}
        url="/browser/PROXY_LIST"
        action={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={openAddProxyDialog}
          >
            添加代理
          </Button>
        }
        dataResolver={(data) => data}
        renderOperations={(_, row) => (
          <Space>
            <Button
              type="primary"
              onClick={() => handleToggleProxy(row)}
            >
              {row.status === "1" ? "禁用" : "启用"}
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleDeleteProxy(row)}
            >
              删除
            </Button>
          </Space>
        )}
      />

      <Modal
        title="添加代理"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleAddProxy}
      >
        <Form
          style={{ margin: "24px 0" }}
          form={form}
          initialValues={initialValue}
        >
          <Form.Item label="节点名称" name="name">
            <Input placeholder="请输入代理节点名称" />
          </Form.Item>
          <Form.Item label="节点路径" name="url">
            <Input placeholder="请输入代理节点路径" />
          </Form.Item>
          <Form.Item label="节点地区" name="region">
            <Input placeholder="请输入代理节点所在国家/地区" />
          </Form.Item>
          <Form.Item label="节点说明" name="description">
            <Input placeholder="请输入代理节点说明" />
          </Form.Item>
          <Form.Item label="是否启用" name="status">
            <Switch
              defaultChecked={initialValue.status}
              checkedChildren="是"
              unCheckedChildren="否"
              onChange={setChecked}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
