import axios from "axios";
import { API_URL } from "./env";
import { message } from "antd";

export const service = axios.create({
  baseURL: API_URL + "/api/v1/admin",
  headers: {
    "Content-Type": "application/json",
  },
});

service.interceptors.response.use(
  (response: any) => {
    const bizRes = response.data;

    if (bizRes.code === 0) {
      return bizRes.data;
    } else {
      message.error(bizRes.message);
    }
  },
  (e) => {
    if (axios.isAxiosError(e)) {
      switch (e.response?.status) {
        case 403:
          message.error("无权限");
          break;
        case 401:
          message.error("未登录");
          break;
        default:
          console.log(e);
          message.error("未知错误");
          break;
      }
    } else {
      message.error("未知错误");
    }

    throw e;
  }
);
