import { useMemo, useRef, useState } from "react";
import { Page } from "../components/Page/Page";
import { Button, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { COLUMN, TYPE } from "../constant";

export const User = () => {
  const navigate = useNavigate();
  const tableRef = useRef<any>();
  const initialValue = useMemo(() => {
    return {
      type: "",
    };
  }, []);

  const handleViewDetail = (...data: any) => {
    const [, row] = data;

    navigate(`/user/${row.user_id}`)
  };

  const search = useMemo(
    () => [
      {
        type: "select",
        label: "注册方式",
        key: "type",
        options: [
          { label: "全部", value: "" },
          { label: "手机", value: "phone" },
          { label: "邮箱", value: "email" },
        ],
      },
      {
        type: "input",
        label: "联系信息",
        placeholder: "邮箱或手机号",
        key: "user",
      },

      {
        type: "input",
        label: "昵称",
        placeholder: "昵称",
        key: "nickname",
      },
    ],
    []
  );
  return (
    <Page
      ref={tableRef}
      rowKey="userId"
      search={search}
      title="用户列表"
      searchInitalValue={initialValue}
      resultColumns={COLUMN[TYPE.USR]}
      url="/browser/USER"
      dataResolver={(data) => {
        return data;
      }}
      renderOperations={(...data: any) => (
        <Button type="link" onClick={() => handleViewDetail(...data)}>
          查看详情
        </Button>
      )}
    />
  );
};
