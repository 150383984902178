import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";

export const History = () => {
  const search = useMemo(
    () => [
      { type: "input", label: "设备ID", key: "id" },
      { type: "input", label: "链接", key: "url" },
    ],
    []
  );
  return (
    <Page
      search={search}
      rowKey="id"
      resultColumns={COLUMN[TYPE.HIS]}
      url="/browser/HISTORY_LIST"
      dataResolver={(data) => data}
    />
  );
};
