import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { useParams, useSearchParams } from "react-router-dom";
import { COLUMN, TYPE } from "../constant";

export const Contact = () => {
  // const search = useMemo(
  //   () => [
  //     {
  //       type: "input",
  //       label: "设备ID",
  //       key: "id",
  //       placeholder: "设备ID，精确匹配",
  //     },
  //     {
  //       type: "input",
  //       label: "通讯录名称",
  //       key: "name",
  //       placeholder: "通讯录名称，模糊匹配",
  //     },
  //     {
  //       type: "input",
  //       label: "电话号码",
  //       key: "phone",
  //       placeholder: "电话号码，模糊匹配",
  //     },
  //     {
  //       type: "input",
  //       label: "邮箱",
  //       key: "email",
  //       placeholder: "邮箱，模糊匹配",
  //     },
  //   ],
  //   []
  // );

  return (
    <Page
      title="通讯录列表"
      rowKey="id"
      resultColumns={COLUMN[TYPE.CON]}
      url="/browser/CONTACT_LIST"
      dataResolver={(data) => data}
    />
  );
};
