import { Button, Form, Input, Pagination, Select, TableColumnType } from "antd";
import "./Page.scss";
import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { service } from "../../api";
import { SearchOutlined } from "@ant-design/icons";
import { BrowserTable } from "../Table/Table";

export interface PageProps {
  search?: SearchItem[];
  extraQuery?: Record<string, string>;
  rowKey: string;
  searchInitalValue?: Record<string, any>;
  resultColumns: Array<ResultColumn & Partial<TableColumnType<void>>>;
  title?: string;
  action?: ReactNode;
  url: string;
  dataResolver: (data: any) => {
    list: Array<Record<string, any>>;
    total: number;
    offset: number;
  };
  renderOperations?: (...data: any) => JSX.Element;
}

export interface SearchItem {
  key: string;
  label: string;
  type: string;
  placeholder?: string;
  options?: Array<{ value: string; label: string }>;
}

export interface ResultColumn {
  key: string;
  label: string;
}

export const Page = React.forwardRef((props: PageProps, ref: any) => {
  const pageSize = 20;
  const [data, setData] = useState<any>({
    list: [],
    total: 0,
    offset: 0,
    extra: {},
  });
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const fetchData = (payload: any) => {
    return service
      .request({
        url: props.url,
        // url: "/wall/list",
        method: "GET",
        params: {
          ...payload,
          ...props.extraQuery,
        },
      })
      .then((data) => {
        const listData = props.dataResolver(data);

        if (listData === undefined || listData === null) {
          setData({
            list: [],
            offset: 0,
            total: 0,
            extra: {},
          });
        }

        setData(listData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClick = () => {
    const formData = form.getFieldsValue();

    setFormData(formData);

    fetchData({
      ...formData,
      offset: 1,
    });
  };

  const refetchData = (offset: any) => {
    return fetchData({
      offset,
      ...formData,
      ...data.extra,
    });
  };

  useEffect(() => {
    fetchData({ offset: 1 });
  }, [props.url, props.extraQuery]);

  useImperativeHandle(
    ref,
    () => {
      return {
        reloadTable: () => {
          refetchData(data.offset);
        },
      };
    },
    [data.offset]
  );

  return (
    <div className="page" style={{ marginBottom: 20 }}>
      {props.search && props.search.length > 0 ? (
        <div className="search">
          <Form
            initialValues={props.searchInitalValue}
            form={form}
            layout="inline"
          >
            {props.search.map((item) => {
              if (item.type === "select") {
                return (
                  <Form.Item
                    className="form-item"
                    label={item.label}
                    name={item.key}
                    key={item.key}
                  >
                    <Select
                      allowClear={false}
                      placeholder={item.placeholder}
                      options={item.options}
                    ></Select>
                  </Form.Item>
                );
              }

              if (item.type === "input") {
                return (
                  <Form.Item
                    className="form-item"
                    label={item.label}
                    name={item.key}
                    key={item.key}
                  >
                    <Input placeholder={item.placeholder} />
                  </Form.Item>
                );
              }

              return null;
            })}
            <Form.Item>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                onClick={handleClick}
              >
                搜索
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : null}
      <div className="result-table">
        <div className="table-title">
          <div className="table-title-text">{props.title}</div>
          {props.action ? (
            <div className="table-action">{props.action}</div>
          ) : null}
        </div>
        {data ? (
          <div style={{ overflowX: "auto" }}>
            <BrowserTable
              resultColumns={props.resultColumns}
              data={data.list}
              rowKey={props.rowKey}
              renderOperations={props.renderOperations}
            ></BrowserTable>
            {data.total > pageSize ? (
              <Pagination
                pageSize={pageSize}
                total={data.total}
                current={data.offset}
                showSizeChanger={false}
                onChange={refetchData}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
});
