import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";

export const Software = () => {
  const search = useMemo(
    () => [
      {
        type: "input",
        label: "软件名称",
        key: "appName",
        placeholder: "软件名称，模糊匹配",
      },
      {
        type: "input",
        label: "设备ID",
        key: "uuid",
        placeholder: "设备ID，精确匹配",
      },
    ],
    []
  );
  return (
    <Page
      title="应用列表"
      search={search}
      rowKey="id"
      resultColumns={COLUMN[TYPE.PKG]}
      url="/browser/PACKAGE_LIST"
      dataResolver={(data) => data}
    />
  );
};
