export const NotMatched = () => {
  return (
    <div
      style={{
        height: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#999",
        fontSize: 16,
        minHeight: 300,
      }}
    >
      未匹配到页面，请检查页面地址是否正确
    </div>
  );
};
