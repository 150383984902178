import { useMemo } from "react";
import { Page } from "../components/Page/Page";
import { COLUMN, TYPE } from "../constant";

export const Dial = () => {
  const search = useMemo(
    () => [
      {
        type: "select",
        label: "设备ID",
        key: "id",
        placeholder: "设备ID，精确匹配",
      },
      {
        type: "input",
        label: "姓名",
        key: "name",
        placeholder: "姓名，模糊匹配",
      },
      {
        type: "input",
        label: "电话号码",
        key: "phone",
        placeholder: "电话号码，模糊匹配",
      },
    ],
    []
  );
  return (
    <Page
      title="通话记录列表"
      search={search}
      rowKey="id"
      resultColumns={COLUMN[TYPE.CALL]}
      url="/browser/CALL_LOG_LIST"
      dataResolver={(data) => data}
    />
  );
};
